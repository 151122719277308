import { engineeringSuccessList, spotifyUrl } from "@constant";

const TrackList = () => (
  <div className="TrackList">
    {engineeringSuccessList.map(({ name, emoji, aria }) => (
      <div key={name}>
        <a href={spotifyUrl} rel="noopener noreferrer" target="_blank">
          {name}
          <span role="img" aria-label={aria}>
            {emoji}
          </span>
        </a>
      </div>
    ))}
  </div>
);

export default TrackList;
