import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  LandingPage,
  AlbumPage,
  PhotoPage,
  InfoPage,
  MenuPage,
  MediaPage,
  MVPage,
  Footer,
} from "./site";
import { menu } from "@asset";

const App = () => {
  const [isShow, toggleIsShow] = useState(false);
  useEffect(() => toggleIsShow(true), []);
  return (
    <CSSTransition
      unmountOnExit
      in={isShow}
      timeout={250}
      classNames="fade"
      appear
    >
      <div className="App">
        <div className="logo gold">Joey Huang</div>
        <Link to="/menu">
          <div className="menu-text">menu</div>
        </Link>
        <Link to="/menu">
          <img className="menu-icon" src={menu} alt="menu" />
        </Link>
        <LandingPage />
        <MVPage />
        <AlbumPage />
        <PhotoPage />
        <Footer />
      </div>
    </CSSTransition>
  );
};

const routes = [
  { path: "/info", Component: InfoPage },
  { path: "/media", Component: MediaPage },
  { path: "/menu", Component: MenuPage },
  { path: "/", Component: App },
];

const TheRoutes = () => {
  return (
    <Router>
      <Routes>
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path} element={<Component />}></Route>
        ))}
      </Routes>
    </Router>
  );
};

export default TheRoutes;
