import imagesLoaded from "imagesloaded";

export const removeLoader = () => {
  const loadingPageElement = document.getElementById("loading-page");
  loadingPageElement.classList.add("loaded");
};

const resizeGridItem = (item) => {
  const grid = document.getElementsByClassName("grid")[0];
  const rowHeight = parseInt(
    window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
  );
  const rowGap = parseInt(
    window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
  );
  const rowSpan = Math.ceil(
    (item.querySelector(".content").getBoundingClientRect().height + rowGap) /
      (rowHeight + rowGap)
  );
  item.style.gridRowEnd = "span " + rowSpan;
};

export const resizeAllGridItems = () => {
  const allItems = document.getElementsByClassName("media-card");
  for (let x = 0; x < allItems.length; x++) {
    resizeGridItem(allItems[x]);
    imagesLoaded(allItems[x], resizeInstance);
  }
};

const resizeInstance = (instance) => {
  const item = instance.elements[0];
  resizeGridItem(item);
};
