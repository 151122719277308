import * as THREE from "three";
import { useEffect, memo } from "react"; // , { Suspense }
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Cloud = ({ onLoad }) => {
  var scene = new THREE.Scene();
  var camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  camera.position.z = 4;
  let renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.domElement.id = "three";
  useEffect(() => {
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    window.addEventListener(
      "resize",
      () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      },
      false
    );
    return window.removeEventListener("resize", () => {});
  }, [camera, renderer]);
  const loader = new GLTFLoader();
  loader.load(
    "/cloud.glb",
    (gltf) => {
      const envMap = new THREE.CubeTextureLoader().load([
        "/texture/posx.jpg",
        "/texture/negx.jpg",
        "/texture/posy.jpg",
        "/texture/negy.jpg",
        "/texture/posz.jpg",
        "/texture/negz.jpg",
      ]);
      const color = new THREE.Color("rgb(233, 210, 140)");
      const material = new THREE.MeshBasicMaterial({ color, envMap });
      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) child.material = material;
      });
      scene.add(gltf.scene);
      onLoad();
      renderer.domElement.classList.add("loaded");
    },
    (xhr) => {
      console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    (err) => {
      console.log("An error happened: ", err);
    }
  );

  let mouseX = 0;
  let mouseY = 0;

  let windowHalfX = window.innerWidth / 2;
  let windowHalfY = window.innerHeight / 2;

  const onDocumentMouseMove = (event) => {
    mouseX = (event.clientX - windowHalfX) / 20;
    mouseY = (event.clientY - windowHalfY) / 20;
  };

  useEffect(() => {
    window.addEventListener("mousemove", onDocumentMouseMove, false);
    return window.removeEventListener("mousemove", () => {});
  });

  let animate = function () {
    requestAnimationFrame(animate);

    camera.position.x += (mouseX - camera.position.x) * 0.001;
    camera.position.y += (-mouseY - camera.position.y) * 0.005;

    camera.lookAt(scene.position);
    renderer.render(scene, camera);
  };
  animate();
  useEffect(() => {
    return () => {
      renderer.dispose();
      scene.traverse((object) => {
        if (!object.isMesh) return;
        object.geometry.dispose();
        if (object.material.isMaterial) {
          cleanMaterial(object.material);
        } else {
          for (const material of object.material) cleanMaterial(material);
        }
      });
      document.body.removeChild(renderer.domElement);
    };
  }, [renderer, scene]);
  return <div />;
};

const cleanMaterial = (material) => {
  material.dispose();
  for (const key of Object.keys(material)) {
    const value = material[key];
    if (value && typeof value === "object" && "minFilter" in value) {
      value.dispose();
    }
  }
};

export default memo(Cloud);
