const PhotoPage = () => (
  <div className="photo-page">
    <div className="grid-br"></div>
    <div className="grid-left"></div>
    <div className="grid-right"></div>
    <img
      className="landing-photo grid-j1 lazyload"
      data-src="/photo/joey-landing-photo-1.png"
      alt=""
    />
    <img
      className="landing-photo grid-j2 lazyload"
      data-src="/photo/joey-landing-photo-2.png"
      alt=""
    />
    <img
      className="landing-photo grid-j3 lazyload"
      data-src="/photo/joey-landing-photo-3.png"
      alt=""
    />
    <img
      className="landing-photo grid-j4 lazyload"
      data-src="/photo/joey-landing-photo-4.png"
      alt=""
    />
  </div>
);

export default PhotoPage;
