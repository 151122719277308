import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { medias } from "@constant";
import { removeLoader, resizeAllGridItems } from "@util";
import { menu } from "@asset";

const MediaPage = () => {
  useEffect(() => removeLoader(), []);
  useEffect(() => {
    window.onload = resizeAllGridItems();
    window.addEventListener("resize", resizeAllGridItems);
  });
  return (
    <div className="media-page white-blue">
      <Helmet>
        <title>Joey Huang | Media</title>
      </Helmet>
      <Link to="/">
        <div className="logo blue">Joey Huang</div>
      </Link>
      <Link to="/menu">
        <img className="menu-icon" src={menu} alt="menu" />
      </Link>
      <div className="grid">
        {medias.map(({ imgUrl, title, url }, idx) => (
          <a
            key={title + idx}
            rel="noopener noreferrer"
            target="_blank"
            href={url}
            className="media-card"
          >
            <div className="content">
              <img className="lazyload" src={imgUrl} alt={title} />
              <div className="title">{title}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default MediaPage;
