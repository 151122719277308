const SocialLinks = ({ links }) => (
  <div className="SocialLinks">
    {links.map(({ name, url }) => (
      <div key={name}>
        <a href={url} rel="noopener noreferrer" target="_blank">
          {name}
        </a>
      </div>
    ))}
  </div>
);

export default SocialLinks;
