export const spotifyUrl =
  "https://open.spotify.com/album/52OP8gMDQbTEgusFIfGtLC?si=_vCTmPcGTtGllVi0jvqZHQ";

export const primaryLinks = [
  { name: "Insta.", url: "https://www.instagram.com/joeyhuang.io" },
  { name: "Fb.", url: "https://www.facebook.com/joey.huang.91" },
  { name: "Yt.", url: "https://www.youtube.com/user/joeyhuangjjj" },
  {
    name: "Spotify.",
    url: spotifyUrl,
  },
];

export const fullLinks = [
  ...primaryLinks,
  { name: "GitHub.", url: "https://github.com/csiejoey" },
  { name: "StreetVoice.", url: "https://streetvoice.com/joeyhuang/" },
];

export const engineeringSuccessList = [
  { name: "HELLO WORLD", emoji: "☁️", aria: "cloud" },
  { name: "DIVIDE and CONQUER", emoji: "⚔️", aria: "crossed-swords" },
  { name: "AOTTER FOREVER", emoji: "🔋", aria: "battery" },
  { name: "LIVE MESSENGER", emoji: "💌", aria: "love-letter" },
  { name: "ASYNC LUV", emoji: "🚸", aria: "children-crossing" },
  { name: "ENGINEERING SUCCESS", emoji: "🦄", aria: "check-mark" },
  { name: "wretch.cc", emoji: "🏡", aria: "house-with-garden" },
  { name: "RUBBER DUCK", emoji: "🐥", aria: "standing-chick" },
  { name: "PROMISED FULFILLED", emoji: "💾", aria: "floppy-disk" },
];

export const medias = [
  {
    imgUrl:
      "/media/EP83 | DevOps是甚麼？懶人最適合的職位？嘻哈與工程的結合！ft. 就已 Joey.jpeg",
    url:
      "https://open.spotify.com/episode/2fyBbnTYmu01vcZsgIMv0s?si=vtcc03eZSdqSfopEW002kw&dl_branch=1",
    title:
      "EP83 | DevOps是甚麼？懶人最適合的職位？嘻哈與工程的結合！ft. 就已 Joey",
    date: "2021/07/19",
  },
  {
    imgUrl:
      "/media/老人公寓｜Ep.8 (下集)阿公阿嬤的饒舌專場！躁起來！feat.就已Joey｜傻子公寓第五季.jpeg",
    url: "https://www.youtube.com/watch?v=I9pFfJoMCjs",
    title:
      "老人公寓｜Ep.8 (下集)阿公阿嬤的饒舌專場！躁起來！feat.就已Joey｜傻子公寓第五季",
    date: "2020/12/20",
  },
  {
    imgUrl:
      "/media/老人公寓｜Ep.8 (上集)阿公阿嬤的饒舌專場！躁起來！feat.就已Joey｜傻子公寓第五季.jpeg",
    url: "https://www.youtube.com/watch?v=b_jiwmWcOb8",
    title:
      "老人公寓｜Ep.8 (上集)阿公阿嬤的饒舌專場！躁起來！feat.就已Joey｜傻子公寓第五季",
    date: "2020/12/18",
  },
  {
    imgUrl:
      "/media/EP28 -(下)【就已Joey Huang】這張專輯『工程名就』找來了意想不到的歌手合作！！！.jpeg",
    url:
      "https://open.spotify.com/episode/0VFjkYCXE81BD1k3vcqzFU?si=bSe48P7nQD6OfsAw2swGlw",
    title:
      "EP28 -(下)【就已Joey Huang】這張專輯『工程名就』找來了意想不到的歌手合作！！！",
    date: "2020/12/04",
  },
  {
    imgUrl:
      "/media/EP27 -(上)網路百萬點閱神曲『即時通』創作者【就已Joey Huang】喚起大家時代的眼淚💧.jpeg",
    url:
      "https://open.spotify.com/episode/0pkXemvaCHbzO04d7bjiiD?si=o5mY95KtTdqxEZopvJPWkw",
    title:
      "EP27 -(上)網路百萬點閱神曲『即時通』創作者【就已Joey Huang】喚起大家時代的眼淚💧",
    date: "2020/11/23",
  },
  {
    imgUrl: "/media/⟪榮譽獺友⟫ MV 拍攝幕後花絮 影片背後原來是這樣的啊！.jpeg",
    url: "https://www.youtube.com/watch?v=S60ElZOXb_8",
    title: "⟪榮譽獺友⟫ MV 拍攝幕後花絮 影片背後原來是這樣的啊！",
    date: "2020/11/08",
  },
  {
    imgUrl: "/media/Exclusive Interview with @joeyhuang.io.png",
    url: "https://www.instagram.com/p/CHUky1nnyFM/",
    title: "Exclusive Interview with @joeyhuang.io",
    date: "2020/11/08",
  },
  {
    imgUrl:
      "/media/【錦時THATStight X 大嘻地3.0】在KTV裡工程名就的男人 ft.就已Joey Huang.jpeg",
    url: "https://www.youtube.com/watch?v=UmNk_WiDNi4",
    title:
      "【錦時THATStight X 大嘻地3.0】在KTV裡工程名就的男人 ft.就已Joey Huang",
    date: "2020/11/03",
  },
  {
    imgUrl:
      "/media/EP17 | 從公館遊樂園到工程名就 - Joey Huang就已的Hybrid人生.png",
    url:
      "https://open.spotify.com/episode/2saS0h4CBscBq302FrdQqG?si=a096465ed8594a28",
    title: "EP17 | 從公館遊樂園到工程名就 - Joey Huang就已的Hybrid人生",
    date: "2020/10/31",
  },
  {
    imgUrl: "/media/【大嘻地3.0】就已 Joey Huang.png",
    url: "https://www.instagram.com/p/CG-CtChHNtf/",
    title: "【大嘻地3.0】就已 Joey Huang",
    date: "2020/10/30",
  },
  {
    imgUrl:
      "/media/黑卡專訪來自雲端的科技饒舌歌手《就已 Joey》即刻進入賽博龐克的雲上國.png",
    url: "https://hackazine-tw.com/joey-engineering-success/",
    title:
      "黑卡專訪來自雲端的科技饒舌歌手《就已 Joey》即刻進入賽博龐克的雲上國",
    date: "2020/10/27",
  },
  {
    imgUrl:
      "/media/嘻一口專訪 │ 工程師唱饒舌竟然那麼炸！從 “工程掠地” 再到 “工程名就”、一窺 就已 逐步建構的「嘻哈工程學」.jpeg",
    url: "https://www.cool-style.com.tw/wd2/archives/561691",
    title:
      "嘻一口專訪 │ 工程師唱饒舌竟然那麼炸！從 “工程掠地” 再到 “工程名就”、一窺 就已 逐步建構的「嘻哈工程學」！",
    date: "2020/10/25",
  },
  {
    imgUrl: "/media/誰說工程師不會做音樂？就已工程名就專輯專訪.jpeg",
    url: "https://www.youtube.com/watch?v=7LQlzTv6d78",
    title: '誰說工程師不會做音樂？就已"工程名就"專輯專訪',
    date: "2020/10/14",
  },
  {
    imgUrl:
      "/media/跨界烤肉!!人生第一次歌手訪談讓我問問題你們不要再吃了!!! Ft.就已 @Joey Huang 許瓊文 Wen Hsu.jpeg",
    url: "https://www.youtube.com/watch?v=OkJyXYDWUp0",
    title:
      "跨界烤肉!!人生第一次歌手訪談讓我問問題你們不要再吃了!!! Ft.就已 @Joey Huang 許瓊文 Wen Hsu",
    date: "2020/10/03",
  },
  {
    imgUrl:
      "/media/程式語言也可以用唱的？科技詩人「就已」發行首張科技概念專輯《工程名就》.jpeg",
    url: "https://tw.mensuno.asia/article/detail?sn=5213",
    title:
      "程式語言也可以用唱的？科技詩人「就已」發行首張科技概念專輯《工程名就》",
    date: "2020/09/26",
  },
  {
    imgUrl: "/media/紫屋靡音EP4.jpeg",
    url: "https://open.spotify.com/episode/34zKsRYs71ijJkDqfaPaxp",
    title: '『紫屋靡音』EP.4 如何"HOW TO"工程名就?(下) ft.就已 Joey Huang',
    date: "2020/09/25",
  },
  {
    imgUrl: "/media/紫屋靡音EP3.jpeg",
    url:
      "https://open.spotify.com/episode/7IZT67umm293hv7YkD5e13?si=-55oaVQ_TaamzSiGwqHZLg",
    title:
      '『紫屋靡音』EP.3 如何"HOW TO"工程名就?(上) ft.就已 Joey Huang＋高浩哲K-How',
    date: "2020/09/24",
  },
  {
    imgUrl:
      "/media/百萬點閱文青Rapper上線！電音與嘻哈，迸出新滋味！feat. 就已 Joey Huang.jpeg",
    url:
      "https://open.spotify.com/episode/5dhUjkbyN2kELaoY1oqRvl?si=GhwTiU9USDmadzwKPUUNiA",
    title:
      "百萬點閱文青Rapper上線！電音與嘻哈，迸出新滋味！feat. 就已 Joey Huang",
    date: "2020/09/24",
  },
  {
    imgUrl:
      "/media/【Q&A】老派靈魂與科技的衝突美，窺探工程詩人就已Joey的創作世界.png",
    url: "https://www.instagram.com/p/CFRjN5snzuS/",
    title: "【Q&A】老派靈魂與科技的衝突美，窺探工程詩人就已Joey的創作世界",
    date: "2020/09/18",
  },
  {
    imgUrl:
      "/media/工程饒舌詩人登場 就已 Joey 的「Google 寫詞法」首度公開.jpeg",
    url: "http://omusic.friday.tw/news_content.php?sn_id=202009080000003",
    title: "工程饒舌詩人登場 就已 Joey 的「Google 寫詞法」首度公開",
    date: "2020/09/08",
  },
  {
    imgUrl:
      "/media/就已首張個人專輯《工程名就》用做產品的態度來完成一張專輯.jpeg",
    url: "https://blow.streetvoice.com/51099",
    title: "就已首張個人專輯《工程名就》用做產品的態度來完成一張專輯",
    date: "2020/09/04",
  },
  {
    imgUrl:
      "/media/就已《工程名就》專輯上線！如何把程式化為情話？專訪饒舌工程師的自學心法.jpeg",
    url:
      "https://medium.com/@alice.yeh/%E5%B0%87%E7%A8%8B%E5%BC%8F%E8%AA%9E%E8%A8%80%E5%8C%96%E7%82%BA%E6%83%85%E8%A9%B1-%E6%8E%A8%E5%87%BA%E8%87%BA%E7%81%A3%E9%A6%96%E5%BC%B5%E7%A7%91%E6%8A%80%E5%98%BB%E5%93%88%E5%B0%88%E8%BC%AF-%E5%B0%88%E8%A8%AA%E9%A5%92%E8%88%8C%E5%B7%A5%E7%A8%8B%E5%B8%AB%E5%B0%B1%E5%B7%B2-3374fe57081d",
    title:
      "就已《工程名就》專輯上線！如何把程式化為情話？專訪饒舌工程師的自學心法",
    date: "2020/09/01",
  },
  {
    imgUrl: "/media/將程式語言連結至感情問題 就已推出新歌〈非同步的愛〉.jpeg",
    url: "https://blow.streetvoice.com/50490/",
    title: "將程式語言連結至感情問題 就已推出新歌〈非同步的愛〉",
    date: "2020/07/20",
  },
  {
    imgUrl:
      "/media/浪漫派饒舌歌手就已邀山姆合作 釋出新單曲〈早點睡，早點好起來〉.jpeg",
    url: "https://blow.streetvoice.com/49341/",
    title: "浪漫派饒舌歌手就已邀山姆合作 釋出新單曲〈早點睡，早點好起來〉",
    date: "2020/04/20",
  },
  {
    imgUrl:
      "/media/專題｜回顧時代的眼淚的崛起與沒落，無名小站、即時通、索尼愛立信，你的青春裡也有它們嗎？.png",
    url: "https://agirls.aotter.net/post/56930",
    title:
      "專題｜回顧時代的眼淚的崛起與沒落，無名小站、即時通、索尼愛立信，你的青春裡也有它們嗎？",
    date: "2020/02/29",
  },
  {
    imgUrl: "/media/Yahoo即時通掰！永遠下線的青春，致那些年用過的通訊軟體.jpeg",
    url:
      "https://www.bnext.com.tw/article/49466/yahoo-messenger-is-shutting-down-on-july-17-redirects-users-to-group-messaging-app-squirrel",
    title: "Yahoo即時通掰！永遠下線的青春，致那些年用過的通訊軟體 ",
    date: "2018/06/11",
  },
  {
    imgUrl: "/media/又一時代眼淚！Yahoo 奇摩即時通 7 月 17 日正式停止服務.jpeg",
    url:
      "https://www.businesstoday.com.tw/article/category/80392/post/201806110019/%E5%8F%88%E4%B8%80%E6%99%82%E4%BB%A3%E7%9C%BC%E6%B7%9A%EF%BC%81Yahoo%20%E5%A5%87%E6%91%A9%E5%8D%B3%E6%99%82%E9%80%9A%207%20%E6%9C%88%2017%20%E6%97%A5%E6%AD%A3%E5%BC%8F%E5%81%9C%E6%AD%A2%E6%9C%8D%E5%8B%99",
    title: "又一時代眼淚！Yahoo 奇摩即時通 7 月 17 日正式停止服務 | 今周刊",
    date: "2018/06/11",
  },
  {
    imgUrl:
      "/media/又一時代眼淚！Yahoo 奇摩即時通 7 月 17 日正式停止服務 介面.jpeg",
    url:
      "https://www.inside.com.tw/article/13200-yahoo-messenger-is-shutting-down-on-july-17",
    title: "又一時代眼淚！Yahoo 奇摩即時通 7 月 17 日正式停止服務 | INSIDE",
    date: "2018/06/11",
  },
  {
    imgUrl:
      "/media/安安、你好、幾歲、住哪？「 Yahoo奇摩即時通 」，確定將於這天「停止服務」！.jpeg",
    url:
      "https://today.line.me/tw/v2/article/%E5%AE%89%E5%AE%89%E3%80%81%E4%BD%A0%E5%A5%BD%E3%80%81%E5%B9%BE%E6%AD%B2%E3%80%81%E4%BD%8F%E5%93%AA%EF%BC%9F%E3%80%8C+Yahoo%E5%A5%87%E6%91%A9%E5%8D%B3%E6%99%82%E9%80%9A+%E3%80%8D%EF%BC%8C%E7%A2%BA%E5%AE%9A%E5%B0%87%E6%96%BC%E9%80%99%E5%A4%A9%E3%80%8C%E5%81%9C%E6%AD%A2%E6%9C%8D%E5%8B%99%E3%80%8D%EF%BC%81-RomnYZ",
    title:
      "安安、你好、幾歲、住哪？「 Yahoo奇摩即時通 」，確定將於這天「停止服務」！ ",
    date: "2018/06/11",
  },
  {
    imgUrl:
      "/media/叮咚！有人在家嗎？「即時通」之歌唱出把妹回憶 網友哭成一片.jpeg",
    url: "https://news.ltn.com.tw/news/novelty/breakingnews/2131585",
    title: "叮咚！有人在家嗎？「即時通」之歌唱出把妹回憶 網友哭成一片",
    date: "2017/07/14",
  },
  {
    imgUrl: "/media/饒舌神曲「即時通之歌」超還原！暗戀回憶逼哭七、八年級.jpeg",
    url: "https://www.ettoday.net/news/20170714/966956.htm",
    title: "饒舌神曲「即時通之歌」超還原！暗戀回憶逼哭七、八年級",
    date: "2017/07/14",
  },
  {
    imgUrl: "/media/「叮咚有人在家嗎？」太想到雅虎實習 即時通神曲就此誕生.jpeg",
    url: "https://www.setn.com/News.aspx?NewsID=272547",
    title: "「叮咚有人在家嗎？」太想到雅虎實習　即時通神曲就此誕生",
    date: "2017/07/13",
  },
  {
    imgUrl: "/media/職人企劃 - Joey 就已, Rapper.jpeg",
    url:
      "http://www.haveanice.com/article/%E8%81%B7%E4%BA%BA%E4%BC%81%E5%8A%83---joey%E5%B0%B1%E5%B7%B2-rapper",
    title: "職人企劃 - Joey 就已, Rapper",
    date: "2016/02/16",
  },
];
