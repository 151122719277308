import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";
import { SocialLinks } from "@component";
import { primaryLinks } from "@constant";
import { removeLoader } from "@util";

const MenuPage = () => {
  useEffect(() => removeLoader(), []);
  return (
    <div className="menu-page">
      <Helmet>
        <title>Joey Huang | Menu</title>
      </Helmet>
      <img className="sky lazyload" data-src="/sky.jpg" alt="sky" />
      <div className="nav">
        <Link to="/">home.</Link>
        <Link to="/info">info.</Link>
        <Link to="/media">media.</Link>
        <HashLink to="/#album">album.</HashLink>
      </div>
      <SocialLinks links={primaryLinks} />
    </div>
  );
};

export default MenuPage;
