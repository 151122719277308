import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SocialLinks } from "@component";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { client } from "../../client";
import { fullLinks } from "@constant";
import { removeLoader } from "@util";
import { menu } from "@asset";

const InfoPage = () => {
  const [description, setDescription] = useState("");
  useEffect(() => removeLoader(), []);
  useEffect(() => {
    client
      .getEntry("1xxhG7mXbZBWUJTrxhEEEC")
      .then((res) => {
        setDescription(res.fields.desc);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="info-page white-blue">
      <Helmet>
        <title>Joey Huang | Info</title>
      </Helmet>
      <Link to="/">
        <div className="logo blue">Joey Huang</div>
      </Link>
      <Link to="/menu">
        <img className="menu-icon" src={menu} alt="menu" />
      </Link>
      <div className="flex">
        <div className="content">
          <div className="desc">{documentToReactComponents(description)}</div>
        </div>
        <img
          className="info-photo lazyload"
          data-src="/photo/joey-info.png"
          alt=""
        />
      </div>
      <SocialLinks links={fullLinks} />
    </div>
  );
};

export default InfoPage;
