import { goldCloud } from "@asset";

const Footer = () => (
  <div className="Footer white-blue">
    <img
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
      className="goldCloud"
      src={goldCloud}
      alt="goldCloud"
    />
  </div>
);

export default Footer;
