const Notice = () => (
  <div className="Notice">
    <a
      href="https://lnkfi.re/AsyncLuv"
      rel="noopener noreferrer"
      target="_blank"
    >
      Now available on all streaming platforms
    </a>
  </div>
);

export default Notice;
