import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { client } from "../../client";

const MVPage = () => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    client
      .getEntry("45k7WpQ2vasQqkZKuzCUmx")
      .then((res) => setUrl(res.fields.url))
      .catch(console.error);
  }, []);
  return (
    <div className="mv-page">
      <div className="player-wrapper">
        <ReactPlayer
          width="100%"
          height="100%"
          className="react-player"
          url={url}
        />
      </div>
    </div>
  );
};

export default MVPage;
