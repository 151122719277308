import { Parallax } from "react-scroll-parallax";
import { TrackList } from "@component";

const AlbumPage = () => {
  return (
    <div id="album" className="album-page">
      <Parallax className="album-desc" translateY={[-10, 40]}>
        <div className="title">Engineering Success</div>
        <div className="quote">
          <div className="rect" />
          <div className="text">
            <div className="vertical-line" />
            <div className="desc">
              Programmatic hip-hop album by Taiwanese singer Joey Huang
            </div>
          </div>
        </div>
      </Parallax>
      <Parallax translateY={[20, -10]}>
        <TrackList />
      </Parallax>
    </div>
  );
};

export default AlbumPage;
