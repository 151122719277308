import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { SocialLinks, Notice, Cloud } from "@component";
import { arrow } from "@asset";
import { primaryLinks } from "@constant";
import { removeLoader } from "@util";

const LandingPage = () => {
  return (
    <div className="landing-page blue-white">
      <Helmet>
        <title>Joey Huang</title>
      </Helmet>
      <img className="clouds lazyload" data-src="/cloudLandscape.png" alt="" />
      <img className="arrow" src={arrow} alt="arrow" />
      {!isMobile && <Cloud onLoad={removeLoader} />}
      {isMobile && (
        <img
          className="gold-cloud lazyload"
          data-src="/gold-cloud.png"
          onLoad={removeLoader}
          alt=""
        />
      )}
      {/*!isMobile && (
        <img
          className="gold-cloud lazyload"
          data-src="/gold-cloud-hq.png"
          onLoad={removeLoader}
          alt=""
        />
      )*/}
      <Notice />
      <SocialLinks links={primaryLinks} />
    </div>
  );
};

export default LandingPage;
